import React from "react";
import BookCard from "./card/BookCard";
import "./app.css";
import Header from "./Header";
import { Routes, Route } from "react-router-dom";
import Footer from "./Footer";
import BookDetails from "./details/BookDetails";
import SimilarBooks from "./similarbooks/SimilarBooks";
import SearchBookCard from "./card/SearchBookCard";
import SearchBookDetails from "./details/SearchBookDetails";

const App = () => {
  return (
    <div className="app">
      <div className="bookstore">
        <Header />
        <Routes>
          {/* <Route path="/" element={<BookCard key="book-card" />} /> */}
          <Route
            path="/details/:id"
            element={<BookDetails key="book-details" />}
          />
          <Route
            path="/similarbooks/:type"
            element={<SimilarBooks key="similar-books" />}
          />
          <Route
            path="/search-resuls/:name"
            element={<SearchBookCard key="search-book-card" />}
          />
          <Route
            path="/search-results/:name/details"
            element={<SearchBookDetails key="search-book-details" />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default App;
